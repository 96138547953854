<template>
  <div class="property-update-form">
    <Flash title="Congrats" :content="flashContent" :show="!!flashContent" :hideFlash="hideFlash"></Flash>
    <FormFieldsComponent
      :formFields="baseFields"
      :editable="editable"
      ref="baseFields"
      @onCompletionChange="onFieldsCompletionChange">
      <template #updateCoverPhoto="{ field }">
        <b-field :label="field.label">
          <b-field class="file is-success" :class="{'has-name': !!customFields.updateCoverPhotoFile}">
            <b-upload v-model="customFields.updateCoverPhotoFile" class="file-label" rounded :disabled="!editable">
              <span class="file-cta">
                <span class="icon file-icon"><i class="fa fa-upload has-text-white"></i></span>
                <span class="file-label has-text-white">{{ customFields.updateCoverPhotoFile | formatFileLabel }}</span>
              </span>
            </b-upload>
          </b-field>
        </b-field>
        <figure v-if="updateCoverPhotoUrl" class="image is-128x128 cover-photo" :style="{ backgroundImage: 'url(' + updateCoverPhotoUrl + ')' }"></figure>
      </template>
    </FormFieldsComponent>
    <b-field>
      <b-upload v-model="customFields.attachments"
        multiple
        drag-drop
        :disabled="!editable">
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon
                  icon="upload"
                  size="is-large">
              </b-icon>
            </p>
            <p>Drop your files here or click to upload</p>
          </div>
        </section>
      </b-upload>
    </b-field>
    <div class="tags">
      <template v-for="(file, index) in customFields.attachments">
        <span v-if="!file.remove"
          :key="index"
          class="tag is-primary" >
          {{file.fileName || file.name}}
          <button class="delete is-small"
            type="button"
            @click="removeAttachment(index)">
          </button>
        </span>
      </template>
    </div>
    <slot></slot>
    <b-button v-if="editable" @click="onSave" type="is-blue" :disabled="!isValid || isSaving">Save</b-button>
  </div>
</template>
<script>
import FormFieldsComponent from '@components/form/form-fields'
import Flash from '@components/modal/slide-down-flash.vue'
import { camelToSnakeCase } from '@utils/common-methods/common'
import { baseFields } from '@utils/data/addy_plus_property_update_fields'

export default {
  components: {
    FormFieldsComponent,
    Flash,
  },
  props: {
    propertyUpdate: Object,
    isSaving: Boolean,
    flashContent: {
      type: String,
      default: ''
    },
    hideFlash: Function,
    editable: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    propertyUpdate() {
      const copy = JSON.parse(JSON.stringify(this.baseFields))
      copy.forEach((field) => {field.value = this.propertyUpdate[field.key]})
      this.baseFields = copy
      this.customFields.attachments = this.propertyUpdate.generalAttachments || []
    },
    flashContent(newContent) {
      if (!newContent) return

      // Reset form fields after getting success flash content
      this.customFields.updateCoverPhotoFile = null
      if (this.propertyUpdate) {
        this.customFields.attachments = this.propertyUpdate.generalAttachments
      } else {
        this.customFields.attachments = []
        const copy = JSON.parse(JSON.stringify(this.baseFields))
        copy.forEach((field) => {field.value = null})
        this.baseFields = copy
      }

      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.hideFlash()
      }, 4000)
    },
  },
  data() {
    return {
      baseFields,
      customFields: {
        updateCoverPhotoFile: null,
        attachments: [],
      },
      isValid: false,
      timer: null,
    }
  },
  filters: {
    formatFileLabel(file) {
      if (file) return file.name
      return 'Click to upload'
    },
  },
  computed: {
    updateCoverPhotoUrl() {
      return this.propertyUpdate?.updateCoverPhotoUrl
    }
  },
  methods: {
    onSave() {
      const payload = this.$refs.baseFields.formData
      const formData = new FormData()

      // Build form data from property update attributes
      Object.keys(payload).forEach((key) => {
        if (key === 'updateCoverPhoto') {
          if (!this.customFields.updateCoverPhotoFile) return
          formData.append(camelToSnakeCase(key), this.customFields.updateCoverPhotoFile)
        } else {
          formData.append(camelToSnakeCase(key), payload[key])
        }
      })

      // Build form data from general attachments attributes
      const nestedFormkey = 'general_attachments_attributes'
      this.customFields.attachments.forEach((attachment, index) => {
        if (attachment instanceof File) { // Files to be created
          formData.append(`${nestedFormkey}[${index}][file]`, attachment)
        } else if (attachment.remove) { // Files to be removed
          formData.append(`${nestedFormkey}[${index}][id]`, attachment.id)
          formData.append(`${nestedFormkey}[${index}][_destroy]`, true)
        }
      })

      this.$emit('onSave', formData)
    },
    onFieldsCompletionChange(isCompleted) {
      this.isValid = isCompleted
    },
    removeAttachment(index) {
      const attachment = this.customFields.attachments[index]
      if (attachment instanceof File) {
        this.customFields.attachments.splice(index, 1)
      } else {
        attachment.remove = true
        this.customFields.attachments.splice(index, 1, attachment)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.property-update-form {
  max-width: 800px;
}
.image.cover-photo {
  background-size: cover;
  background-position: 50% 50%;
}
</style>
