const baseFields = [
  {
    key: 'updateCoverPhoto',
    label: 'Cover Photo',
    type: 'custom',
    required: false,
    value: null,
  },
  {
    key: 'updateDate',
    label: 'Date',
    type: 'datepicker',
    required: true,
    value: null,
  },
  {
    key: 'updateTitle',
    label: 'Title',
    type: 'text-input',
    required: true,
    value: null,
  },
  {
    key: 'updateText',
    label: 'Message',
    type: 'text-area',
    required: true,
    value: null,
  },
  {
    key: 'updateType',
    label: 'Type',
    type: 'select',
    options: [
      'Rent Paid',
      'Rent Bounced',
      'Bill',
      'Event',
      'Maintenance',
      'Taxes',
      'Insurance',
      'Community',
      'Vacancy',
      'Distribution',
      'AGM',
      'Miscellaneous',
      'News',
      'Quarterly Update',
      'Annual Financials'
    ].map((x) => {return { key: x, name: x }}),
    required: true,
    value: null,
  },
  {
    key: 'videoUrls',
    label: 'Video URLs',
    icon: 'link',
    type: 'tag-input',
    required: false,
    value: [],
  },
  {
    key: 'notifyMembers',
    label: 'Notify Members?',
    type: 'checkbox',
    value: false,
  },
  {
    key: 'published',
    label: 'Published?',
    type: 'checkbox',
    value: false,
  },
]

export {
  baseFields,
}
