import axios from '@lib/axios/middleware'
import { getImbyDomain } from '@utils/common-methods/getImbyDomain'

const getImbyRoute = (url) => {
  if (window.location.hostname === 'localhost') return url
  return getImbyDomain() + url
}

/**
 * Fetch property updates
 * @param {String} propertyId
 * @param {Int} page
 * @param {Int} perPage
 * @return {Promise}
 */
export const fetchPropertyUpdates = (propertyId, page = 1, perPage = 10) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/properties/${propertyId}/updates`), { params: { limit: perPage, page: page } })
}

/**
 * Fetch property update
 * @param {String} propertyId
 * @param {String} updateId
 * @return {Promise}
 */
export const fetchPropertyUpdate = (propertyId, updateId) => {
  return axios.get(getImbyRoute(`/api/addy_plus/v1/properties/${propertyId}/updates/${updateId}`))
}

/**
 * Update property update
 * @param {String} propertyId
 * @param {String} updateId
 * @param {FormData} formData
 * @return {Promise}
 */
export const updatePropertyUpdate = (propertyId, updateId, formData) => {
  return axios.put(getImbyRoute(`/api/addy_plus/v1/properties/${propertyId}/updates/${updateId}`), formData)
}

/**
 * Create property update
 * @param {String} propertyId
 * @param {FormData} formData
 * @return {Promise}
 */
export const createPropertyUpdate = (propertyId, formData) => {
  return axios.post(getImbyRoute(`/api/addy_plus/v1/properties/${propertyId}/updates`), formData)
}

/**
 * Delete property update
 * @param {String} propertyId
 * @param {String} updateId
 * @return {Promise}
 */
export const deletePropertyUpdate = (propertyId, updateId) => {
  return axios.delete(getImbyRoute(`/api/addy_plus/v1/properties/${propertyId}/updates/${updateId}`))
}
